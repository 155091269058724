<template>
  <div class="integration-settings-list">
    <div>
      Manage your App Store Console and Google Play integrations
    </div>
    <div class="integration-settings-list-table">
      <progress-indicator v-if="apps.length === 0">
        Loading...
      </progress-indicator>
      <SimpleInfoTable
        v-else
        :table-id="'integration-settings-list-table'"
        :items="apps"
        :show-pagination="false"
        :columns="{
          'app': {header: 'App Name', orientation: 'left'},
          'as_api': {header: 'API Connect'},
          'as_email': {header: 'Share by Email'},
        }"
      >
        <template v-slot:items-app="slotProps">
          <div class="title-block-wrap">
            <app-title :title="slotProps.item.app.title"
                       :logo="slotProps.item.app.logo"
                       :store-link="slotProps.item.app.store_link"
                       :store="slotProps.item.app.store"
            />
            <table-actions-tooltip v-if="!slotProps.item.app.added"
                                   @click="addToMyApps(slotProps.item.app.id)"
                                   class="add-to-my-app">
              <template v-slot:tooltip-icon>
                <svg-icon icon="plus" />
              </template>
              <template v-slot:tooltip-text>
                This application is not in My Apps list. Add it to track statistics
              </template>
            </table-actions-tooltip>
          </div>
        </template>
        <template v-slot:items-as_api="slotProps">
          <template v-if="slotProps.item.app.store === 'APP_STORE'">
            <div class="integration-status-wrap" v-if="slotProps.item.as_api">
              <div class="integration-status"
                   @click="clickStatus(slotProps.item)"
                   :class="{
                    'success-status': slotProps.item.as_api_status === 'success',
                    'pending-status': slotProps.item.as_api_status === 'in_process',
                    'error-status': slotProps.item.as_api_status === 'error',
                   }">
                <div class="status-icon">
                  <svg-icon icon="check-alt"
                            class="success-status-icon"
                            v-if="slotProps.item.as_api_status === 'success'" />
                  <div class="pending-status-icon"
                       v-if="slotProps.item.as_api_status === 'in_process'">
                    <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.20668 1.46001C7.26668 0.193344 10.7733 1.64668 12.0467 4.70668C13.3133 7.76668 11.86 11.2733 8.80001 12.5467C5.74001 13.82 2.23334 12.36 0.96001 9.30001C-0.306656 6.24001 1.14668 2.73334 4.20668 1.46001Z" stroke="#EE9D24" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M6.31323 4.32666V7.42666L8.75323 8.91333" stroke="#EE9D24" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                  <div class="error-status-icon"
                       v-if="slotProps.item.as_api_status === 'error'">
                    <svg id="error-circle" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8 2C11.3133 2 14 4.68667 14 8C14 11.3133 11.3133 14 8 14C4.68667 14 2 11.3133 2 8C2 4.68667 4.68667 2 8 2Z" stroke="#D44324" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M8 8.33333V5" stroke="#D44324" stroke-linecap="round" stroke-linejoin="round"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.33325 10.8332C7.33325 10.4637 7.63044 10.1665 7.99992 10.1665C8.36939 10.1665 8.66659 10.4637 8.66659 10.8332C8.66659 11.2026 8.36939 11.4998 7.99992 11.4998C7.63044 11.4998 7.33325 11.2026 7.33325 10.8332Z" fill="#D44324"/>
                    </svg>
                  </div>
                </div>
                <div class="status">
                  <template v-if="slotProps.item.as_api_status === 'success'">
                    Connected
                  </template>
                  <template v-if="slotProps.item.as_api_status === 'in_process'">
                    Pending
                  </template>
                  <template v-if="slotProps.item.as_api_status === 'error'">
                    Need fix
                  </template>
                </div>
              </div>
              <table-actions-tooltip @click="disconnectKey(slotProps.item)"
                                     class="disconnect-icon disconnect-key-icon">
                <template v-slot:tooltip-icon>
                  <svg-icon icon="close-alt" />
                </template>
                <template v-slot:tooltip-text>
                  Disconnect
                </template>
              </table-actions-tooltip>
            </div>
            <div v-else class="connection-link">
              <router-link :to="'/account/integration-settings/app-store/api/' + slotProps.item.app.id">
                <svg-icon icon="plus" /> Connect
              </router-link>
            </div>
          </template>
        </template>
        <template v-slot:items-as_email="slotProps">
          <template v-if="slotProps.item.app.store === 'APP_STORE'">
            <div class="integration-status-wrap" v-if="slotProps.item.as_email">
              <div class="integration-status"
                   :class="{
                    'success-status': slotProps.item.as_email_status === 'success',
                    'pending-status': slotProps.item.as_email_status === 'in_process',
                    'error-status': slotProps.item.as_email_status === 'error',
                   }">
                <div class="status-icon">
                  <svg-icon icon="check-alt"
                            class="success-status-icon"
                            v-if="slotProps.item.as_email_status === 'success'" />
                  <div class="pending-status-icon"
                       v-if="slotProps.item.as_email_status === 'in_process'">
                    <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.20668 1.46001C7.26668 0.193344 10.7733 1.64668 12.0467 4.70668C13.3133 7.76668 11.86 11.2733 8.80001 12.5467C5.74001 13.82 2.23334 12.36 0.96001 9.30001C-0.306656 6.24001 1.14668 2.73334 4.20668 1.46001Z" stroke="#EE9D24" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M6.31323 4.32666V7.42666L8.75323 8.91333" stroke="#EE9D24" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                </div>
                <div class="status">
                  <template v-if="slotProps.item.as_email_status === 'success'">
                    Connected
                  </template>
                  <template v-if="slotProps.item.as_email_status === 'in_process'">
                    Pending
                  </template>
                </div>
              </div>
              <table-actions-tooltip @click="disconnectEmail(slotProps.item)"
                                     class="disconnect-icon disconnect-email-icon">
                <template v-slot:tooltip-icon>
                  <svg-icon icon="close-alt" />
                </template>
                <template v-slot:tooltip-text>
                  Disconnect
                </template>
              </table-actions-tooltip>
            </div>
            <div v-else class="connection-link">
              <router-link :to="'/account/integration-settings/app-store/share-email/' + slotProps.item.app.id">
                <svg-icon icon="plus" /> Connect
              </router-link>
            </div>
          </template>
        </template>
      </SimpleInfoTable>
    </div>
  </div>

  <custom-modal :opened="errorModalApp"
                @closeModal="errorModalApp = null"
                :max-width="300"
                class-name="integration-error-modal">
    <template v-slot:name>
      <div class="error-modal-title">
        <div class="circle"></div>
        <div>Problem with API Key</div>
      </div>
    </template>

    <div class="error-modal-content">
      <div class="mb-14">Please check API Key information to fix it.</div>
      <router-link :to="'/account/integration-settings/app-store/api/' + errorModalApp + '/edit'">
        Check API Key information <svg-icon icon="arrow-right" />
      </router-link>
    </div>
  </custom-modal>

  <custom-modal :opened="disconnectEmailModalApp !== null"
                @closeModal="disconnectEmailModalApp = null"
                :max-width="500"
                class-name="integration-disconnect-modal">
    <disconnect-email-modal :connect-app-id="disconnectEmailModalConnectId"
                            :app="disconnectEmailModalApp"
                            @closeModal="closeDisconnectModal" />
  </custom-modal>

  <custom-modal :opened="disconnectKeyModalApp !== null"
                @closeModal="disconnectKeyModalApp = null"
                :max-width="500"
                class-name="integration-disconnect-modal">
    <disconnect-key-modal :app="disconnectKeyModalApp" @closeModal="closeDisconnectModal" />
  </custom-modal>

  <alert :text="alertText" @hide="alertText = ''" />
</template>
<script>
import ProgressIndicator from "@/components/UI/ProgressIndicator/index.vue";
import AppTitle from "@/components/Billets/AppTitle/index.vue";
import SimpleInfoTable from "@/components/SimpleInfoTable/SimpleInfoTable.vue";
import SvgIcon from "@/components/UI/SvgIcon/index.vue";
import TableActionsTooltip from "@/components/DataTables/Tools/TableActionsTooltip/index.vue";
import {httpRequest} from "@/api";
import {mapGetters} from "vuex";
import Alert from "@/components/UI/Alert/index.vue";
import PreloaderTableSmall from "@/components/UI/PreloaderTableSmall/index.vue";
import DisconnectEmailModal from "@/views/Account/IntegrationSettings/AppStore/DisconnectEmailModal.vue";
import DisconnectKeyModal from "@/views/Account/IntegrationSettings/AppStore/DisconnectKeyModal.vue";

export default {
  name: 'IntegrationSettingsList',
  components: {
    DisconnectKeyModal,
    DisconnectEmailModal,
    PreloaderTableSmall,
    Alert,
    TableActionsTooltip,
    SvgIcon,
    SimpleInfoTable,
    AppTitle,
    ProgressIndicator,
  },
  data() {
    return {
      apps: [],
      errorModalApp: null,
      disconnectEmailModalApp: null,
      disconnectEmailModalConnectId: null,
      disconnectKeyModalApp: null,
      alertText: '',
    };
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    clickStatus(item) {
      if (item.as_api_status === 'error') {
        this.errorModalApp = item.app.id
      }
    },
    async closeDisconnectModal(refresh) {
      this.disconnectEmailModalApp = null;
      this.disconnectKeyModalApp = null;
      if (refresh) {
        await this.fetchList();
      }
    },
    async addToMyApps(appId) {
      let formData = new FormData();
      formData.append('application_id', appId);
      formData.append('country_code', this.currentCountry.code);
      const result = await httpRequest(
        'POST',
        process.env.VUE_APP_API_URL + this.$ApiUrls.myApps.MY_APPLICATIONS,
        formData
      );

      if (result?.applications?.length > 0) {
        this.alertText = 'The app has been successfully added.';
        await this.fetchList();
      }
    },
    async disconnectKey(connectApp) {
      this.disconnectKeyModalApp = {...connectApp.app};
    },
    async disconnectEmail(connectApp) {
      this.disconnectEmailModalApp = {...connectApp.app};
      this.disconnectEmailModalConnectId = connectApp.connect_app_id;
    },
    async fetchList() {
      this.apps = [];
      let result = await httpRequest(
        'GET',
        process.env.VUE_APP_API_URL + this.$ApiUrls.integrations.APP_STORE_CONNECT_LIST
      );
      this.apps = result.list;
    },
  },
  computed: {
    ...mapGetters([
      'currentCountry',
    ]),
  },
}
</script>
<style src="./styles.scss" lang="scss"></style>